@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: 'Pixellari';
    src: url('./fonts/Pixellari.ttf');
}

@font-face {
    font-family: 'Minecraft';
    src: url('./fonts/Minecraft.ttf');
}

@font-face {
    font-family: 'VCR';
    src: url('./fonts/PressStart2P.ttf');
}

@layer components{
    
    .backgroundimage {
        @apply
        bg-[url('./images/mcbg.jpg')]
        h-screen w-screen absolute z-[-1]
        contrast-[.9] bg-cover opacity-40
    }

    .backgroundimage-aboutthegame {
        @apply
        bg-[url('./images/netherack_texure.webp')]
    }

    .backgroundimage-aboutus {
        @apply
        bg-[url('./images/bricks_texture.webp')]
    }

    .backgroundimage-socials {
        @apply
        bg-[url('./images/endstone_texture.webp')]
    }

    .backgroundimage-kits {
        @apply
        bg-[url('./images/netherite_texture.webp')]
    }

    .updatelog {
        @apply
        bg-[url('./images/smoothstone_texture.webp')]
    }

    .mcbutton {
        @apply
        bg-[url('./images/dirt_texture.jpg')] 
        drop-shadow-xl border-2 m-1
        transition-all ease-in-out duration-75
        saturate-100

        p-2

        sm:text-sm sm:mt-1 sm:p-1
        
        hover:text-yellow-200 hover:scale-95 hover:border-yellow-100
        hover:shadow-xl hover:saturate-150 hover:shadow-inner 

        uppercase font-bold
    }

    

}
